var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    { style: _vm.backCss },
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "danger", loading: _vm.btnLoading },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "danger", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.reject({
                        ids: _vm.selectedRowKeys,
                        rejectReason: "",
                        confirm: false,
                      })
                    },
                  },
                },
                [_vm._v("拒绝")]
              )
            : _vm._e(),
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.finish(_vm.selectedRowKeys)
                    },
                  },
                },
                [_vm._v("同意")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "table-page-search-wrapper",
          attrs: { slot: "search" },
          slot: "search",
        },
        [
          _c(
            "a-form",
            {
              attrs: { layout: "inline" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    [
                      _c("a-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入人员昵称" },
                        model: {
                          value: _vm.queryParam.searchText,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "searchText", $$v)
                          },
                          expression: "queryParam.searchText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.searchQuery },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "padding-top": "10px", "padding-bottom": "20px" } },
        [
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.radioValue,
                callback: function ($$v) {
                  _vm.radioValue = $$v
                },
                expression: "radioValue",
              },
            },
            [
              _c("a-radio", { attrs: { value: "1" } }, [_vm._v("全部")]),
              _c("a-radio", { attrs: { value: "2" } }, [_vm._v("已处理")]),
              _c("a-radio", { attrs: { value: "3" } }, [_vm._v("未处理")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        ref: "table",
        attrs: {
          id: "out-table",
          size: "middle",
          rowKey: (record) => record.id,
          columns: _vm.columns,
          customRow: () => {},
          dataSource: _vm.dataSource,
          pagination: _vm.ipagination,
          loading: _vm.loading,
          scroll: { x: "max-content", y: "calc(100vh - 456px)" },
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 45,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "expandedRowRender",
            fn: function (record) {
              return _c("p", { staticStyle: { margin: "0" } }, [
                _c(
                  "span",
                  [
                    !record.isWorkOvertime
                      ? _c("span", [_vm._v("变更内容：")])
                      : _vm._e(),
                    _vm._l(record.changes, function (item, index) {
                      return _c("div", {
                        key: index,
                        domProps: { innerHTML: _vm._s(item) },
                      })
                    }),
                  ],
                  2
                ),
              ])
            },
          },
          {
            key: "sort",
            fn: function (text, record, index) {
              return _c("span", {}, [
                _vm._v("\n      " + _vm._s(index + 1) + "\n    "),
              ])
            },
          },
          {
            key: "processStatus",
            fn: function (text, record) {
              return _c("span", {}, [
                record.processStatus == "AGREE"
                  ? _c(
                      "span",
                      [
                        _c("a-icon", {
                          staticStyle: {
                            color: "green",
                            "margin-right": "5px",
                          },
                          attrs: { type: "check-circle", theme: "filled" },
                        }),
                        _vm._v("已通过\n      "),
                      ],
                      1
                    )
                  : record.processStatus == "DISAGREE"
                  ? _c(
                      "span",
                      [
                        _c("a-icon", {
                          staticStyle: { color: "red", "margin-right": "5px" },
                          attrs: { type: "close-circle", theme: "filled" },
                        }),
                        _vm._v("未通过\n      "),
                      ],
                      1
                    )
                  : _c("span", [_vm._v("等待中")]),
              ])
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c("span", {}, [
                record.processStatus != "AGREE"
                  ? _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.finish([record.id])
                          },
                        },
                      },
                      [_vm._v("同意")]
                    )
                  : _vm._e(),
                record.processStatus != "AGREE" &&
                record.processStatus != "DISAGREE"
                  ? _c(
                      "a",
                      {
                        staticStyle: { "margin-left": "15px" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openRejectModel(record)
                          },
                        },
                      },
                      [_vm._v("拒绝")]
                    )
                  : _vm._e(),
              ])
            },
          },
          {
            key: "user",
            fn: function (text, record) {
              return _c("span", {}, [
                _c("span", [
                  record.avatar
                    ? _c("img", {
                        staticStyle: {
                          width: "35px",
                          height: "35px",
                          "border-radius": "50%",
                        },
                        attrs: { src: record.avatar, alt: "" },
                      })
                    : _c("img", {
                        staticStyle: {
                          width: "40px",
                          height: "40px",
                          "border-radius": "50%",
                        },
                        attrs: {
                          src: require("./../../assets/tx.png"),
                          alt: "",
                        },
                      }),
                  _c("span", { staticStyle: { "margin-left": "15px" } }, [
                    _vm._v(_vm._s(record.realname)),
                  ]),
                ]),
              ])
            },
          },
          {
            key: "process",
            fn: function (text, record) {
              return _c("span", {}, [
                record.processorId
                  ? _c("span", [
                      record.processorAvatar
                        ? _c("img", {
                            staticStyle: {
                              width: "35px",
                              height: "35px",
                              "border-radius": "50%",
                            },
                            attrs: { src: record.processorAvatar, alt: "" },
                          })
                        : _c("img", {
                            staticStyle: {
                              width: "40px",
                              height: "40px",
                              "border-radius": "50%",
                            },
                            attrs: {
                              src: require("./../../assets/tx.png"),
                              alt: "",
                            },
                          }),
                      _c("span", { staticStyle: { "margin-left": "15px" } }, [
                        _vm._v(_vm._s(record.processorName)),
                      ]),
                    ])
                  : _vm._e(),
              ])
            },
          },
          {
            key: "files",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                _vm._l(record.files, function (i, index) {
                  return _c("div", { key: index, attrs: { href: i } }, [
                    _c(
                      "span",
                      {
                        staticClass: "file-span",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.play(i)
                          },
                        },
                      },
                      [_vm._v(_vm._s(i.split("/")[i.split("/").length - 1]))]
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: { "margin-left": "3px" },
                        attrs: {
                          href:
                            i +
                            "?response-content-type=application/octet-stream",
                          target: "downloadFile",
                        },
                      },
                      [_c("a-icon", { attrs: { type: "download" } })],
                      1
                    ),
                  ])
                }),
                0
              )
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: { title: "操作", visible: _vm.visible, width: 650 },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("a-input", {
            attrs: { placeholder: "如拒绝申请请在此处填入理由" },
            model: {
              value: _vm.applyReason,
              callback: function ($$v) {
                _vm.applyReason = $$v
              },
              expression: "applyReason",
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "a-button",
                { staticClass: "mr-3", on: { click: _vm.handleCancel } },
                [_vm._v("取消")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.reject({
                        ids: [_vm.openData.id],
                        rejectReason: _vm.applyReason,
                        confirm: false,
                      })
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }