<template>
  <body-layout :style="backCss">
    <div class="actions flex" slot="actions">
      <a-button type="danger" class="mr-3" :loading="btnLoading" v-if="selectedRowKeys.length" @click="batchDel"
        >删除</a-button
      >
      <a-button
        type="danger"
        class="mr-3"
        v-if="selectedRowKeys.length"
        :loading="btnLoading"
        @click="
          reject({
            ids: selectedRowKeys,
            rejectReason: '',
            confirm: false
          })
        "
        >拒绝</a-button
      >
      <a-button type="primary" :loading="btnLoading" v-if="selectedRowKeys.length" @click="finish(selectedRowKeys)"
        >同意</a-button
      >
      <!--  <a-button v-has="'ProductDetailPop:add'" type="primary" @click="openAddForm">新建</a-button> -->
    </div>
    <div class="table-page-search-wrapper" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input style="width:300px" v-model="queryParam.searchText" placeholder="请输入人员昵称" />
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div style="padding-top:10px;padding-bottom:20px;">
      <a-radio-group v-model="radioValue">
        <a-radio value="1">全部</a-radio>
        <a-radio value="2">已处理</a-radio>
        <a-radio value="3">未处理</a-radio>
      </a-radio-group>
    </div>
    <a-table
      ref="table"
      id="out-table"
      size="middle"
      :rowKey="record => record.id"
      :columns="columns"
      :customRow="() => {}"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 456px)' }"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
      @change="handleTableChange"
    >
      <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <span>
          <span v-if="!record.isWorkOvertime">变更内容：</span>
          <div v-for="(item, index) in record.changes" :key="index" v-html="item"></div>
        </span>
      </p>
      <span slot="sort" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="processStatus" slot-scope="text, record">
        <span v-if="record.processStatus == 'AGREE'">
          <a-icon style="color:green;margin-right:5px" type="check-circle" theme="filled" />已通过
        </span>
        <span v-else-if="record.processStatus == 'DISAGREE'">
          <a-icon type="close-circle" style="color:red;margin-right:5px" theme="filled" />未通过
        </span>
        <span v-else>等待中</span>
      </span>
      <span slot="action" slot-scope="text, record">
        <a v-if="record.processStatus != 'AGREE'" @click.stop="finish([record.id])">同意</a>
        <a
          v-if="record.processStatus != 'AGREE' && record.processStatus != 'DISAGREE'"
          style="margin-left:15px"
          @click.stop="openRejectModel(record)"
          >拒绝</a
        >
      </span>
      <span slot="user" slot-scope="text, record">
        <span>
          <img v-if="record.avatar" :src="record.avatar" style="width:35px;height:35px;border-radius: 50%;" alt="" />
          <img v-else src="./../../assets/tx.png" style="width:40px;height:40px;border-radius: 50%;" alt="" />
          <span style="margin-left:15px">{{ record.realname }}</span>
        </span>
      </span>
      <span slot="process" slot-scope="text, record">
        <span v-if="record.processorId">
          <img
            v-if="record.processorAvatar"
            :src="record.processorAvatar"
            style="width:35px;height:35px;border-radius: 50%;"
            alt=""
          />
          <img v-else src="./../../assets/tx.png" style="width:40px;height:40px;border-radius: 50%;" alt="" />
          <span style="margin-left:15px">{{ record.processorName }}</span>
        </span>
      </span>
      <span slot="files" slot-scope="text, record">
        <div :href="i" v-for="(i, index) in record.files" :key="index">
          <span @click.stop="play(i)" class="file-span">{{ i.split('/')[i.split('/').length - 1] }}</span>
          <a
            :href="i + '?response-content-type=application/octet-stream'"
            target="downloadFile"
            style="margin-left:3px;"
          >
            <a-icon type="download" />
          </a>
        </div>
      </span>
    </a-table>
    <a-modal title="操作" :visible="visible" @cancel="handleCancel" :width="650">
      <!--       <div style="line-height:32px;">
        <span style="font-weight:700">{{ openData.realname }}</span>
        <span> 修改了可安排数量：</span>
        <div>
          <div v-for="(item, index) in openData.changes" :key="index" v-html="item"></div>
          <span>请及时确认！</span>
        </div>
      </div>
      <div style="margin-top:32px">
        <a-input placeholder="如拒绝申请请在此处填入理由" v-model="applyReason"></a-input>
      </div> -->
      <a-input placeholder="如拒绝申请请在此处填入理由" v-model="applyReason"></a-input>
      <div slot="footer">
        <a-button class="mr-3" @click="handleCancel">取消</a-button>
        <a-button
          type="danger"
          @click="
            reject({
              ids: [openData.id],
              rejectReason: applyReason,
              confirm: false
            })
          "
          >拒绝</a-button
        >
        <!-- <a-button type="primary" class="mr-3" @click="finish([openData.id])">同意</a-button> -->
      </div>
    </a-modal>
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { simpleDebounce } from '@/utils/util'
import { checkPermission } from '@/utils/hasPermission'
import { queryPermissionsByUser } from '@/api/api'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
export default {
  mixins: [JeecgListMixin],
  data() {
    return {
      visible: false,
      positionList: [],
      rowId: '',
      radioValue: '3',
      curRow: [],
      btnLoading: false,
      tablePage: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0
      },
      translateUsers: [],
      userIds: [],
      monthFormat: 'YYYY-MM',
      month: '',
      isEdit: false,
      isAdmin: false,
      searchUsers: [],
      openData: {},
      userInfo: {
        roles: []
      },
      url: {
        list: '/taskSchedule/changeApply/list',
        delete: '/fonts/delete',
        deleteBatch: '/taskSchedule/changeApply/delete'
      },
      color: '',
      applyReason: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
          width: 50
        },
        {
          title: '人员昵称',
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
          width: 180
        },
        {
          title: '申请时间',
          dataIndex: 'gmtCreate',
          width: 180
        },
        {
          title: '申请理由',
          dataIndex: 'applyReason',
          width: 300
        },
        {
          title: '状态',
          dataIndex: 'processStatus',
          scopedSlots: { customRender: 'processStatus' },
          width: 120
        },
        {
          title: '处理人',
          dataIndex: 'process',
          scopedSlots: { customRender: 'process' },
          width: 180
        },
        {
          title: '处理时间',
          dataIndex: 'processTime',
          width: 180
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 150
        }
      ],
      queryParam: {
        processStatuses: 'NONE'
      }
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  watch: {
    radioValue() {
      if (this.radioValue == '3') {
        this.queryParam.processStatuses = 'NONE'
      } else if (this.radioValue == '2') {
        this.queryParam.processStatuses = 'AGREE,DISAGREE'
      } else {
        delete this.queryParam.processStatuses
      }
      this.loadData('1')
    }
  },
  methods: {
    handleCancel() {
      this.visible = false
      this.openData = {}
      this.applyReason = ''
    },
    play(url) {
      if (this.$refs.videoModal) this.$refs.videoModal.open(url)
    },
    openDetail: simpleDebounce(async function(row) {
      this.openDetailSim(row)
    }, 500),
    async openDetailSim(row) {
      this.isAdd = true
      if (this.mytasksDetialPath) {
        this.$router.push({
          path: `${this.mytasksDetialPath}/${row.taskId}/${row.productionId}/${row.chapterId}?${
            this.fromProduction ? 'fromProduction=1' : ''
          }&record=${encodeURIComponent(
            JSON.stringify({ platformId: row.platformId, productionName: row.productionName })
          )}`
        })
      } else if (this.detailPath) {
        this.$router.push({ path: `${this.detailPath}/${row.id}` })
      } else {
        const res = await getAction('/questionAnswer/detail?id=' + row.questionAnswer.id)
        if (res.code == 200) {
          this.curRow = res.data
          this.rowId = row.questionAnswer.id
        }
        /* this.curRow = JSON.parse(JSON.stringify(row)) //防止在drawer中修改内容会影响列表 */
        console.log({ 'openDetail this.curRow': this.curRow })
        if (this.curRow.cardList) {
          this.curRow.cardList.forEach(item => {
            if (item.dataList == null) {
              item.dataList = [{ valueRequired: 'NO' }]
            }
          })
        }
        this.drawerShow = true
        this.isNewForm = true
      }
    },
    getShowHome(time) {
      let text = '未展示'
      if (time) {
        //发布时间是否超过当前时间
        if (new Date(time).getTime() < new Date().getTime()) {
          text = '首页展示中'
        }
      }
      return text
    },
    openRejectModel(record) {
      this.openData = record
      this.visible = true
    },
    async finish(ids) {
      const res = await postAction('/taskSchedule/changeApply/confirm', {
        ids,
        rejectReason: '',
        confirm: true
      })
      queryPermissionsByUser().then(response => {
        Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
        this.$store.commit('SET_INFO', response.data.userInfo)
      })
      if (res.code == 200) {
        this.$message.success('已同意')
        this.handleCancel()
        this.radioValue = '3'
        this.loadData('1')
        this.selectedRowKeys = []
      } else {
        this.$message.error(res.msg)
      }
    },
    async reject(data) {
      this.btnLoading = true
      const res = await postAction('/taskSchedule/changeApply/confirm', data)
      this.btnLoading = false
      if (res.code == 200) {
        this.$message.success('已拒绝')
        this.handleCancel()
        this.radioValue = '3'
        this.loadData('1')
        this.selectedRowKeys = []
        queryPermissionsByUser().then(response => {
          Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
          this.$store.commit('SET_INFO', response.data.userInfo)
        })
      } else {
        this.$message.error(res.msg)
      }
    },
    userSearch(value) {
      if (value) {
        this.searchUsers = this.translateUsers.filter(item => item.userName.indexOf(value) > -1)
      } else {
        this.searchUsers = []
      }
    },
    searchQueryForm() {
      if (this.month && this.month.format) {
        this.queryParam.month = this.month.format(this.monthFormat)
      } else {
        this.queryParam.month = ''
      }
      this.queryParam.userIds = this.userIds
    },
    async stop(record) {
      const res = await putAction('productionOnline/unpublish/' + record.productionOnline.id)
      if (res.code == 200) {
        this.$message.success('已停用')
        this.loadData()
        queryPermissionsByUser().then(response => {
          Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
          this.$store.commit('SET_INFO', response.data.userInfo)
        })
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    async send(record) {
      const res = await putAction('productionOnline/publish', { id: record.productionOnline.id })
      if (res.code == 200) {
        this.$message.success('发布成功')
        this.loadData()
        queryPermissionsByUser().then(response => {
          Vue.ls.set(USER_INFO, response.data.userInfo, 7 * 24 * 60 * 60 * 1000)
          this.$store.commit('SET_INFO', response.data.userInfo)
        })
      } else {
        this.$message.error(res.msg || res.message)
      }
    }
  },
  created() {
    this.$bus.$on('openHelpMsg', record => {
      this.openDetail(record)
    })
  }
}
</script>

<style lang="less" scoped>
.red {
  color: red;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
/deep/ .list-content {
  p {
    margin-bottom: 0 !important;
  }
}
.file-span {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: var(--theme-color);
  }
}
</style>
